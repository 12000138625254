import countriesJsonAllRaw from './countries-all.json'

export type language = {
  name: string
  code: string
  emoji: string
  unicode: string
  image: string
  }
  
export const countriesAllRaw:language[] = countriesJsonAllRaw;

export const countriesAllSorted :language[] = countriesJsonAllRaw.sort((a, b) => {
    const fa = a.name.toLowerCase();
    const fb = b.name.toLowerCase();

    if (fa < fb) { return -1; }
    if (fa > fb) { return 1; }
    return 0;
  });
