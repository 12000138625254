import { Card, CardProps } from '@nextui-org/card'

interface ResumeCardProps extends CardProps {
  colorVariant?: 'white' | 'blue' | 'primary' | 'purple'
}

export default function BlockCard({
  colorVariant = 'white',
  className = '',
  ...props
}: ResumeCardProps) {
  const colorClasses = {
    white: 'bg-white',
    blue: 'bg-blue-50',
    primary: 'bg-primary-50',
    purple: 'bg-purple-50',
  }

  return (
    <Card
      shadow="none"
      className={
        colorClasses[colorVariant] +
        ' p-6 text-left border-1 border-default-200 ' +
        className
      }
      {...props}
    />
  )
}
